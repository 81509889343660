import React, { useState, useEffect } from 'react';
import { Container, Modal, Form, Table } from 'react-bootstrap';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import api from '../../../services/API';

const ModalEditar = ({ show, onHide, data, onSave, editando }) => {
  const [formState, setFormState] = useState({});

  useEffect(() => {
    if (show && data) {
      setFormState({
        id: data.id,
        codigo: data.codigo,
        pontos: data.pontos,
        usos: data.usos,
        expiraEm: data.expiraEm ? moment(data.expiraEm).format('YYYY-MM-DD') : ''
      });
    }
  }, [show, data]);

  const handleInputChange = (field, value) => {
    setFormState(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSaveClick = async () => {
    try {
      await api.put(`/api/codigos-cadastro/${formState.id}`, {
        codigo: formState.codigo,
        pontos: Number(formState.pontos),
        usos: Number(formState.usos),
        expiraEm: formState.expiraEm
      });
      
      toast.success('Código atualizado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
      
      onHide();
      onSave();
    } catch (error) {
      toast.error(error.response?.data?.erro || 'Erro ao atualizar código', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>Editar Código</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Código</Form.Label>
            <Form.Control
              type="text"
              value={formState.codigo || ''}
              onChange={(e) => handleInputChange('codigo', e.target.value.toUpperCase())}
              className="input-theme"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Pontos</Form.Label>
            <Form.Control
              type="number"
              value={formState.pontos || ''}
              onChange={(e) => handleInputChange('pontos', e.target.value)}
              min="1"
              className="input-theme"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Usos Permitidos</Form.Label>
            <Form.Control
              type="number"
              value={formState.usos || ''}
              onChange={(e) => handleInputChange('usos', e.target.value)}
              min="1"
              className="input-theme"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Data de Expiração</Form.Label>
            <Form.Control
              type="date"
              value={formState.expiraEm || ''}
              onChange={(e) => handleInputChange('expiraEm', e.target.value)}
              min={moment().format('YYYY-MM-DD')}
              max="2999-12-31"
              className="input-theme"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button 
          type="button"
          className="btn-vermelho" 
          onClick={onHide}
          disabled={editando}
        >
          Cancelar
        </button>
        <button 
          type="button"
          className="btn-primario" 
          onClick={handleSaveClick}
          disabled={editando}
        >
          {editando ? 'Salvando...' : 'Salvar'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default function ListaCodigoCadastro() {
  const [showModal, setShowModal] = useState(false);
  const [editando, setEditando] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [selectedLink, setSelectedLink] = useState('');
  const [busca, setBusca] = useState('');

  const carregarDados = async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/codigos-cadastro');
      setDados(response.data.rows);
    } catch (error) {
      toast.error('Erro ao carregar dados', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    carregarDados();
  }, []);

  const handleDelete = async (id) => {
    if (!window.confirm('Tem certeza que deseja excluir este código?')) {
      return;
    }

    try {
      await api.delete(`/api/codigos-cadastro/${id}`);
      toast.success('Código excluído com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
      carregarDados();
    } catch (error) {
      toast.error(error.response?.data?.erro || 'Erro ao excluir código', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Link copiado!', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 2000,
    });
  };

  const dadosFiltrados = dados.filter(item => 
    item.codigo.toLowerCase().includes(busca.toLowerCase()) ||
    item.pontos.toString().includes(busca) ||
    item.usos.toString().includes(busca)
  );

  const LinkModal = () => (
    <Modal 
      show={showLinkModal} 
      onHide={() => setShowLinkModal(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Link de Cadastro</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-3 bg-light rounded">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <small className="text-muted">Link para compartilhar:</small>
            <button
              className="btn-primario"
              onClick={() => copyToClipboard(selectedLink)}
              style={{ padding: '5px 15px' }}
            >
              <i className="fas fa-copy mr-2"></i>
              Copiar
            </button>
          </div>
          <div 
            className="p-2 bg-white rounded border" 
            style={{ 
              wordBreak: 'break-all',
              fontSize: '14px'
            }}
          >
            {selectedLink}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button 
          className="btn-vermelho" 
          onClick={() => setShowLinkModal(false)}
        >
          Fechar
        </button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Códigos Cadastrados</h4>
      <hr className="hr-loja mt-0 mb-4" />
      
      <div className="mb-3 d-flex justify-content-end">
        <div className="d-flex">
          <input
            type="text"
            className="input-theme mr-2"
            placeholder="Buscar..."
            value={busca}
            onChange={(e) => setBusca(e.target.value)}
          />
        </div>
      </div>

      <div className="table-responsive">
        <Table className="table-dark text-center rounded">
          <thead>
            <tr>
              <th>Código</th>
              <th>Pontos</th>
              <th>Usos Permitidos</th>
              <th>Usos Realizados</th>
              <th>Expira em</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="6" className="text-center">
                  Carregando... <i className="fas fa-circle-notch fa-spin ml-2"></i>
                </td>
              </tr>
            ) : dadosFiltrados.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center">Nenhum resultado encontrado</td>
              </tr>
            ) : (
              dadosFiltrados.map(item => (
                <tr key={item.id}>
                  <td>{item.codigo}</td>
                  <td>{Number(item.pontos).toLocaleString('pt-BR')}</td>
                  <td>{item.usos}</td>
                  <td>{Number(item.usosRealizados).toLocaleString('pt-BR')}</td>
                  <td>{item.expiraEm ? moment(item.expiraEm).format('DD/MM/YYYY') : 'Sem expiração'}</td>
                  <td>
                    <button
                      className="btn-limpo f-12 p-1 mr-2"
                      onClick={() => {
                        setSelectedData(item);
                        setShowModal(true);
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </button>
                    <button
                      className="btn-limpo f-12 p-1 mr-2"
                      onClick={() => handleDelete(item.id)}
                    >
                      <i className="fas fa-trash f-red"></i>
                    </button>
                    <button
                      className="btn-limpo f-12 p-1"
                      onClick={() => {
                        setSelectedLink(`https://experienciasw.com.br/cadastrar?codigo_cadastro=${item.codigo}`);
                        setShowLinkModal(true);
                      }}
                    >
                      <i className="fas fa-link"></i>
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>

      <ModalEditar
        show={showModal}
        onHide={() => setShowModal(false)}
        data={selectedData}
        onSave={carregarDados}
        editando={editando}
      />

      <LinkModal />
    </Container>
  );
} 