import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import moment from 'moment-timezone';
export default function CadastrarCodigo() {
  const [enviando, setEnviando] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [pontos, setPontos] = useState('');
  const [usos, setUsos] = useState('');
  const [expiraEm, setExpiraEm] = useState('');
  const cadastrarCodigo = async (e) => {
    e.preventDefault();
    
    if (!codigo) {
      toast.error('Informe o código', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!pontos) {
      toast.error('Informe a quantidade de pontos', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!usos) {
      toast.error('Informe a quantidade de usos permitidos', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (expiraEm && moment(expiraEm).isBefore(moment())) {
      toast.error('Data de expiração inválida', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    setEnviando(true);
    try {
      await api.post('/api/codigos-cadastro', {
        codigo,
        pontos: Number(pontos),
        usos: Number(usos),
        expiraEm,
      });
      toast.success('Código cadastrado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCodigo('');
      setPontos('');
      setUsos('');
      setExpiraEm('');
      if (window.refreshDatatable) {
        window.refreshDatatable();
      }
    } catch (error) {
      toast.error(error.response?.data?.erro || 'Erro ao cadastrar código', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
    setEnviando(false);
  };
  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Código</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form">
          <label htmlFor="codigo">Código *</label>
          <input
            id="codigo"
            name="codigo"
            type="text"
            placeholder="Digite o código"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value.toUpperCase())}
          />
          <label htmlFor="pontos">Pontos *</label>
          <input
            id="pontos"
            name="pontos"
            type="number"
            placeholder="Quantidade de pontos"
            value={pontos}
            onChange={(e) => setPontos(e.target.value)}
            min="1"
          />
          <label htmlFor="usos">Usos Permitidos *</label>
          <input
            id="usos"
            name="usos"
            type="number"
            placeholder="Quantidade de usos"
            value={usos}
            onChange={(e) => setUsos(e.target.value)}
            min="1"
          />
          <label htmlFor="expiraEm">Data de Expiração</label>
          <input
            id="expiraEm"
            name="expiraEm"
            type="date"
            placeholder="Data de expiração"
            value={expiraEm}
            onChange={(e) => setExpiraEm(e.target.value)}
            min={moment().format('YYYY-MM-DD')}
            max="2999-12-31"
          />
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button
              className="btn-primario"
              type="submit"
              onClick={cadastrarCodigo}
            >
              CADASTRAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
} 