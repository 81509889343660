import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import { LoaderContext } from '../../Loader';
import './styles.css';

const CATEGORIA_IMGS = {
  ATIVIDADES: '/imagens/categorias/atividades.jpg',
  'BEM-ESTAR': '/imagens/categorias/bem-estar.jpg',
  CONHECIMENTO: '/imagens/categorias/conhecimento.jpg',
  ENTRETENIMENTO: '/imagens/categorias/entretenimento.jpg',
  GASTRONOMIA: '/imagens/categorias/gastronomia.jpg',
  SERVIÇOS: '/imagens/categorias/servicos.jpg',
  VIAGEM: '/imagens/categorias/viagem.jpg',
  'DIA A DIA': '/imagens/categorias/viagem.jpg',
};

const CATEGORIAS_DESCRIPTIONS = {
  ATIVIDADES: `Inúmeras opções esperam por você: desde atrações emocionantes, esportes radicais, até
  momentos de lazer em família. Encontre algo para todas as idades e todas as épocas do ano, espalhado
  por todo o Brasil.`,
  'BEM-ESTAR': `Bem-estar total onde quer que você esteja! Explore uma variedade de locais em todo o país
  e experiências online que promovem saúde, beleza e equilíbrio para a mente, corpo e alma!`,
  CONHECIMENTO: `Explore novas paixões e promova seu crescimento pessoal! Desde artes, esportes,
  música, dança, até cuidados com o desenvolvimento do bebê, há sempre algo novo para descobrir e
  aprimorar!`,
  ENTRETENIMENTO: `Diversão garantida! Para cinéfilos, fanáticos por streamings e amantes de música.
  Um clube feito para todos!`,
  GASTRONOMIA: `Desde o prato refinado ao lanche do dia a dia, oferecemos entrega em domicílio em todo
  o país, com diversos sabores, para todos os paladares!`,
  SERVIÇOS: `Grandes momentos merecem grandes fotos. Aqui, com nossas experiências, você poderá
  registrar e/ou eternizar o melhor de cada instante.`,
  VIAGEM: `Desloque-se pela cidade, reserve viagens ou envie e receba itens com conforto e segurança
  utilizando aplicativo de transporte e mobilidade.`,
  'DIA A DIA': `Facilite seu dia a dia com nossas soluções práticas e convenientes. De serviços 
  essenciais a produtos do cotidiano, encontre tudo o que precisa para tornar sua rotina mais simples 
  e eficiente.`,
};

export function Categorias() {
  const { setIsLoading } = useContext(LoaderContext);
  const [categories, setCategories] = useState([]);

  const loadCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/api/tlc-categories');

      const allCategories = [
        ...response.data.categorias,
        {
          id: 'dia-a-dia',
          nome: 'DIA A DIA'
        }
      ];
      
      setCategories(allCategories);
    } catch (error) {
      console.error('erro: ', error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  useEffect(() => {
    loadCategories();
  }, []);

  const handleCategoryClick = (category) => {
    if (category.nome === 'DIA A DIA') {
      window.location.href = '/premios-dia-dia';
    } else {
      window.location.href = `/experiencias?categoria=${category.id}`;
    }
  };

  return (
    <Container className='categories-container'>
      {categories.map((category) => (
        <section className="wrapper" key={category.id}>
          <div className="img-wrapper">
            {Object.keys(CATEGORIA_IMGS).includes(category.nome) ? (
              <img src={CATEGORIA_IMGS[category.nome]} alt={category.nome} />
            ) : (
              <img
                src="/imagens/image-placeholder-500x500.jpg"
                alt="category-placeholder"
              />
            )}
          </div>
          <div className="description-wrapper">
            <h2>{category.nome}</h2>
            {Object.keys(CATEGORIAS_DESCRIPTIONS).includes(category.nome) && (
              <p>{CATEGORIAS_DESCRIPTIONS[category.nome]}</p>
            )}
            <button
              onClick={() => handleCategoryClick(category)}
              className="btn-primario"
            >
              VER PRODUTOS
            </button>
          </div>
        </section>
      ))}
    </Container>
  );
}
