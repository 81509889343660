import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { store } from '../../store/';

export default function RotaPainel({
  component: Component,
  rotaPrivada = false,
  ...atributos
}) {
  const { autenticadoPainel } = store.getState().autenticacao;
  const { permissao } = store.getState().usuario;

  if (!autenticadoPainel && rotaPrivada) {
    return <Redirect to="/painel/" />;
  }

  if (autenticadoPainel && !rotaPrivada) {
    if (Number(permissao) === 4) {
      return <Redirect to="/painel/resgates" />;
    }
    return <Redirect to="/painel/dashboard" />;
  }

  if (Number(permissao) === 4 && atributos.path === '/painel/dashboard') {
    return <Redirect to="/painel/resgates" />;
  }

  return <Route {...atributos} component={Component} />;
}
