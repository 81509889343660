import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import ModalEditar from './ModalEditar/index';

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'id',
    order: 'desc',
  },
];

export default function OfertasTLC() {
  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [selected, setSelected] = useState([]);
  const [porcentagem, setPorcentagem] = useState('0');
  const [filtroTipo, setFiltroTipo] = useState('todas');

  const handlePorcentagemChange = (e) => {
    const valor = e.target.value.replace('%', '');
    setPorcentagem(valor);
  };

  const usuario = useSelector((state) => {
    return state.usuario;
  });

  const buscarOfertas = async () => {
    setBuscando(true);
    const resultado = await api.get('/api/ofertas');
    if (resultado) {
      let ofertas = resultado.data;
      
      if (filtroTipo === 'novas') {
        ofertas = ofertas.filter(oferta => Number(oferta.pontos || 0) === 0);
      } else if (filtroTipo === 'ativas') {
        ofertas = ofertas.filter(oferta => Number(oferta.pontos || 0) > 0);
      }
      
      setData(ofertas);
    }
    setBuscando(false);
  };

  const aplicarDesconto = async () => {
    if (!porcentagem) {
      toast.error('Informe a porcentagem de desconto');
      return;
    }
    if (selected.length === 0) {
      toast.error('Selecione pelo menos uma oferta');
      return;
    }

    try {
      await api.post('/api/ofertas-tlc/aplicar-desconto', {
        ofertas: selected,
        porcentagem: Number(porcentagem)
      });

      toast.success('Desconto aplicado com sucesso!', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      buscarOfertas();
      setSelected([]);
      setPorcentagem('0');
    } catch (error) {
      console.error('Erro ao aplicar desconto:', error);
      toast.error(error.response?.data?.erro || 'Erro ao aplicar desconto', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected([...selected, row.id]);
    } else {
      setSelected(selected.filter(x => x !== row.id));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      setSelected(ids);
    } else {
      setSelected([]);
    }
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: selected,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    nonSelectable: []
  };

  const syncOffers = async () => {
    try {
      setBuscando(true);
      const resultado = await api.post('/api/ofertas-tlc/sync');
      
      if (resultado?.data) {
        toast.success(`Sincronização concluída! ${resultado.data.atualizadas} ofertas atualizadas e ${resultado.data.criadas} ofertas criadas de um total de ${resultado.data.total}`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        buscarOfertas();
      }
    } catch (error) {
      console.error('Erro ao sincronizar ofertas:', error);
      toast.error(error.response?.data?.erro || 'Erro ao sincronizar ofertas', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } finally {
      setBuscando(false);
    }
  };

  const columns = [
    {
      dataField: 'id',
      text: 'Id',
    },
    {
      dataField: 'offerId',
      text: 'Id da Oferta',
    },
    {
      dataField: 'nome',
      text: 'Nome da Oferta',
      formatter: (cell) => cell || 'Nome não encontrado'
    },
    {
      dataField: 'pontos',
      text: 'Pontos',
      formatter: (cell) => Number(cell || 0) === 0 ? '-' : cell
    },
    {
      dataField: 'pontosDesconto',
      text: 'Pontos com Desconto',
      formatter: (cell) => Number(cell || 0) === 0 ? '-' : cell
    },
    {
      dataField: 'editar',
      text: 'Editar',
      isDummyField: true,
      sort: false,
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            setSelectedData(valor);
            setShowModal(true);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
  ];

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length
      }
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  useEffect(() => {
    buscarOfertas();
  }, [filtroTipo]);

  const atualizarOferta = (dataUpdated) => {
    setData(
      data.map((d) => {
        if (d.id === dataUpdated.id) {
          d = { ...d, ...dataUpdated };
        }
        return d;
      })
    );
  };

  return (
    <Container fluid>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="pt-3 pb-3 f-black">Ofertas TLC</h4>
        <button
          className="btn btn-primary"
          onClick={syncOffers}
          disabled={buscando}
        >
          {buscando ? (
            <>
              Sincronizando...
              <i className="fas fa-circle-notch fa-spin ml-2"></i>
            </>
          ) : (
            'Sincronizar Ofertas'
          )}
        </button>
      </div>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    className="input-theme"
                    placeholder="0%"
                    value={porcentagem ? `${porcentagem}%` : ''}
                    onChange={handlePorcentagemChange}
                    style={{ width: '150px', marginRight: '5px' }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={aplicarDesconto}
                    style={{ height: '35px', padding: '0 10px' }}
                  >
                    Aplicar Desconto
                  </button>
                  <select
                    className="input-select ml-3"
                    value={filtroTipo}
                    onChange={(e) => setFiltroTipo(e.target.value)}
                    style={{ width: '200px' }}
                  >
                    <option value="todas">Todas as Ofertas</option>
                    <option value="novas">Novas Ofertas</option>
                    <option value="ativas">Ofertas Ativas</option>
                  </select>
                </div>
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                selectRow={selectRow}
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                keyField="id"
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalEditar
        show={showModal}
        onHide={() => {
          setSelectedData({});
          setShowModal(false);
        }}
        data={selectedData}
        onChange={setSelectedData}
        atualizarOferta={atualizarOferta}
      />
    </Container>
  );
}
