import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import './styles.css';
import { Link } from 'react-router-dom';

export function PremiosDiaDia() {
  const [premios, setPremios] = useState([]);
  const [busca, setBusca] = useState('');
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPremiosNaPagina, setTotalPremiosNaPagina] = useState(0);
  const itensPorPagina = 12;

  const buscarPremios = async () => {
    try {
      const resultado = await api.get('/api/premios', {
        params: {
          busca: busca,
          ativo: 1,
          categoria: 'Dia a Dia'
        }
      });
      
      if (resultado) {
        setPremios(resultado.data.premios);
        setTotalPremiosNaPagina(resultado.data.premios.length);
      }
    } catch (error) {
      console.error('Erro ao buscar prêmios:', error);
    }
  };

  useEffect(() => {
    document.title = 'Prêmios';
    buscarPremios();
  }, [busca]);

  return (
    <Container className="mb-5">
      <Row>
        <Col xs={12}>
          <div>
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <h5>PRÊMIOS</h5>
              <div className="search-wrapper">
                <input
                  value={busca}
                  onChange={(e) => setBusca(e.target.value)}
                  className="input-theme"
                  placeholder="Buscar prêmios..."
                />
                <button
                  className="btn-busca"
                  onClick={buscarPremios}
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
            <hr className="hr-loja" />
          </div>

          <Row>
            {premios.map((premio) => (
              <Col key={premio.codigo} xs={12} md={4} lg={3} className="mt-4">
                <div className="premio-card">
                  <Link to={`/premio/${premio.codigo}`}>
                    <img
                      alt={premio.nome}
                      className="premio-imagem"
                      src={premio.linkArquivo || premio.diretorioFoto}
                    />
                  </Link>
                  <div className="premio-info">
                    <h6>{premio.nome}</h6>
                    <p className="premio-pontos">{premio.pontos} pontos</p>
                    <Link to={`/premio/${premio.codigo}`}>
                      <button className="btn-primario">
                        VER DETALHES
                      </button>
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}