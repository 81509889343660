import React from 'react';
import './styles.css';
import { Route, Switch } from 'react-router-dom';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';
import PremiosLoja from '../../../components/Loja/PremiosLoja';
import Slider from '../../../components/Loja/Slider';
import DetalhePremio from '../../../components/Loja/DetalhePremio';
import Perfil from '../../../components/Loja/Perfil';
import Pontuacoes from '../../../components/Loja/Pontuacoes';
import Resgates from '../../../components/Loja/Resgates';
import ListaPontos from '../../../components/Loja/ListaPontos';
import Contato from '../../../components/Loja/Contato';
import UploadFoto from '../../../components/Loja/UploadFoto';
import Regulamento from '../../../components/Loja/Regulamento';
import ModalBanner from '../../../components/Loja/ModalBanner';
import ModalRegulamento from '../../../components/Loja/ModalRegulamento';
import Cartao from '../../../components/Loja/Cartao/index';
import Midias from '../../../components/Loja/Midias/index';
import CadastrarVendedor from '../../../components/Loja/CadastrarVendedor/index';
import Privacidade from '../Privacidade';
import { Categorias } from '../../../components/Loja/Categorias';
import Experiencias from '../../../components/Loja/experiencias';
import DetalheResgate from '../../../components/Loja/DetalheResgate';
import Indicacao from '../../../components/Loja/Indicacao';
import { PremiosDiaDia } from '../../../components/Loja/PremiosDiaDia';

export default function Home() {

  const [modalIndex, setModalIndex] = React.useState(0);
  const modals = [
    { name: 'BANNER', condition: () => true },
  ];

  const getNextModalIndex = (currentIndex) => {
    for (let i = currentIndex + 1; i < modals.length; i++)
      if (modals[i].condition()) return i;

    return -1;
  };

  const handleClose = () => {
    const nextModalIndex = getNextModalIndex(modalIndex);
    setModalIndex(nextModalIndex);
  };

  return (
    <>
      <Header auto />
      <main>
        <Switch>
          <Route
            exact
            path="/loja"
            component={(props) => {
              return (
                <div className="mt-5">
                  <PremiosLoja />
                </div>
              );
            }}
          />
          <Route
            exact
            path="/loja/credito"
            component={(props) => <PremiosLoja />}
          />
          <Route
            exact
            path="/premio/:codigo"
            component={(props) => <DetalhePremio />}
          />
          <Route exact path="/perfil" component={(props) => <Perfil />} />
          <Route
            exact
            path="/pontuacoes"
            component={(props) => <Pontuacoes />}
          />
          <Route exact path="/resgates" component={(props) => <Resgates />} />
          <Route
            exact
            path="/resgates/:id"
            component={(props) => <DetalheResgate />}
          />
          <Route
            exact
            path="/enviar-pontuacao"
            component={(props) => <UploadFoto />}
          />
          <Route
            exact
            path="/pontos-produtos"
            component={(props) => <ListaPontos />}
          />
          <Route
            exact
            path="/regulamento"
            component={(props) => <Regulamento />}
          />
          <Route
            exact
            path="/politica-privacidade"
            component={(props) => <Privacidade />}
          />
          <Route
            path="/loja/cadastrar-vendedor/:idRevenda"
            component={(props) => <CadastrarVendedor {...props} />}
          />
          <Route exact path="/cartao" component={(props) => <Cartao />} />
          <Route exact path="/contato" component={(props) => <Contato />} />
          <Route exact path="/fotos-videos" component={(props) => <Midias />} />
          <Route
            exact
            path="/home"
            component={(props) => (
              <div className="mt-5">
                <Slider />
                <Categorias />
              </div>
            )}
          />
          <Route
            exact
            path="/experiencias"
            component={(props) => (
              <div className="mt-5">
                <Experiencias />
              </div>
            )}
          />
          <Route exact path="/indicacao" component={(props) => <Indicacao />} />
          <Route
            exact
            path="/premios-dia-dia"
            component={(props) => (
              <div className="mt-5">
                <PremiosDiaDia />
              </div>
            )}
          />
        </Switch>
      </main>
      <Footer />
      {modalIndex !== -1 && modals[modalIndex].name === 'BANNER' && (
        <ModalBanner handleClose={handleClose} />
      )}
      <ModalRegulamento />
    </>
  );
}
