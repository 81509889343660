/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import sortFunc from '../../../utils/ordernarColunaInt';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import api from '../../../services/API';

export default function PontuacoesCodigo() {
  const [buscando, setBuscando] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [toolkitProps, setToolkitProps] = useState(null);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [sortField, setSortField] = useState('dataUtilizacao');
  const [sortOrder, setSortOrder] = useState('desc');

  const buscarPontuacoes = async () => {
    setBuscando(true);
    try {
      const resultado = await api.get('/api/pontuacoes-codigo', {
        params: {
          search: searchText
        }
      });

      if (resultado?.data) {
        setData(resultado.data || []);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error(error);
      setData([]);
    }
    setBuscando(false);
  };

  useEffect(() => {
    buscarPontuacoes();
  }, [page, sizePerPage, sortField, sortOrder, searchText]);

  const columns = [
    {
      text: 'ID',
      dataField: 'id',
      sort: true,
      hidden: true
    },
    {
      text: 'Código',
      dataField: 'codigo',
      sort: true,
    },
    {
      text: 'Pintor',
      dataField: 'parceiro.nome',
      sort: true,
    },
    {
      text: 'CPF',
      dataField: 'parceiro.cpf',
      formatter: (celula, valor) => {
        const cpf = valor.parceiro?.cpf;
        return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') || '';
      },
      sort: true,
    },
    {
      text: 'Pontos',
      dataField: 'pontos',
      formatter: (celula, valor) => {
        return Number(valor.pontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      text: 'Data de Utilização',
      dataField: 'dataUtilizacao',
      formatter: (celula, valor) => {
        return moment(valor.dataUtilizacao).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    }
  ];

  const options = {
    search: searchText,
    sizePerPage: sizePerPage,
    page: page,
    totalSize: totalSize,
    showTotal: true,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '50', value: 50 },
      { text: '100', value: 100 }
    ],
    onPageChange: (newPage, newSizePerPage) => {
      setPage(newPage);
    },
    onSizePerPageChange: (newSizePerPage, newPage) => {
      setSizePerPage(newSizePerPage);
      setPage(newPage);
    },
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total px-2">
        {size > 0 ? `${from}-${to} de ${size} resultados` : 'Nenhum resultado'}
      </span>
    )
  };

  const handleTableChange = (type, newState) => {
    if (type === 'search'){
      setPage(1);
      setSearchText(newState.searchText);
    }
    if (newState.sortField && newState.sortField !== sortField) {
      setSortField(newState.sortField);
    }
    if (newState.sortOrder && newState.sortOrder !== sortOrder) {
      setSortOrder(newState.sortOrder);
    }
    if (newState.page !== page) {
      setPage(newState.page);
    }
    if (newState.sizePerPage !== sizePerPage) {
      setSizePerPage(newState.sizePerPage);
    }

    buscarPontuacoes();
  };

  const handleSearch = () => {
    if (toolkitProps) {
      toolkitProps.searchProps.onSearch(searchText);
    }
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pontuações por Código</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="mb-3">
        <Col xs={12} className="d-flex justify-content-end">
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginTop: '24px'
          }}>
            <input
              type="text"
              placeholder="Buscar"
              className="input-theme"
              style={{
                width: '200px',
                height: '38px'
              }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button
              className="btn-busca"
              type="submit"
              onClick={handleSearch}
              style={{
                height: '38px',
                minWidth: '38px',
                padding: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <i className="fas fa-search"></i>
            </button>
          </div>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => {
            if (!toolkitProps) {
              setToolkitProps(props);
            }
            return (
              <div>
                <BootstrapTable
                  remote={{ pagination: true, sort: true }}
                  onTableChange={handleTableChange}
                  loading={buscando}
                  noDataIndication={() =>
                    buscando ? (
                      <span>
                        Carregando
                        <i className="fas fa-circle-notch fa-spin ml-3"></i>
                      </span>
                    ) : (
                      <span>Sem resultados</span>
                    )
                  }
                  classes="table-dark text-center rounded"
                  wrapperClasses="rounded"
                  bordered={false}
                  pagination={paginationFactory(options)}
                  defaultSorted={[{ dataField: 'dataUtilizacao', order: 'desc' }]}
                  {...props.baseProps}
                />
              </div>
            );
          }}
        </ToolkitProvider>
      </div>
    </Container>
  );
}