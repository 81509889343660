import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import InputMask from 'react-input-mask';
import validarCpf from '../../../utils/validarCpf';
import './styles.css';

export default function CadastrarAdministrador() {
  const [enviando, setEnviando] = useState(false);
  const [cpf, setCpf] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [permissao, setPermissao] = useState('');
  const [idRevendasPermitidas, setIdRevendasPermitidas] = useState([]);
  const [revendasSelect, setRevendasSelect] = useState([]);

  const cadastrarAdministrador = async (e) => {
    e.preventDefault();
    if (!nome) {
      toast.error('Nome obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!cpf) {
      toast.error('CPF obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!email) {
      toast.error('Email obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!senha) {
      toast.error('Senha obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!permissao) {
      toast.error('Permisão obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (permissao === '3' && idRevendasPermitidas.length === 0) {
      toast.error('Selecione ao menos uma loja', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    let cpfFormatado = cpf.replace(/[^\d]/g, '');

    setEnviando(true);
    const resultado = await api.post('/api/usuarios', {
      nome,
      email,
      cpf: cpfFormatado,
      senha,
      permissao,
      idRevendasPermitidas: idRevendasPermitidas.join(','),
    });

    if (resultado) {
      toast.success('Administrador cadastrado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      resetForm();
    }
    setEnviando(false);
  };

  const resetForm = () => {
    setCpf('');
    setNome('');
    setEmail('');
    setSenha('');
    setPermissao('');
    setIdRevendasPermitidas([]);
    document
      .querySelectorAll('input[type=checkbox]')
      .forEach((el) => (el.checked = false));
  };

  const buscarCpf = async (cpf) => {
    cpf = cpf.replace(/[^\d]/g, '');
    if (!cpf) return;
    const resultado = await api.get(`/api/usuarios?cpf=${cpf}`);
    if (resultado && resultado.data.usuarios.lenght > 0) {
      toast.error('Usuário já cadastrado', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCpf('');
    }
  };

  useEffect(() => {
    buscarRevendas();
  }, []);

  const buscarRevendas = async () => {
    const resultado = await api.get('/api/revendas?ativo=1');
    if (resultado && resultado.data.revendas) {
      setRevendasSelect(resultado.data.revendas);
    }
  };

  const validarBuscarCpf = (e) => {
    const cpf = e.target.value;
    if (!cpf) return;
    if (!validarCpf(cpf)) {
      toast.error('CPF não é válido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCpf('');
    } else {
      buscarCpf(cpf);
    }
  };

  const setRevendasPermitidas = (e) => {
    const id = e.target.value;

    if (e.target.checked) {
      idRevendasPermitidas.push(id);
      setIdRevendasPermitidas(idRevendasPermitidas);
    } else {
      idRevendasPermitidas.splice(idRevendasPermitidas.indexOf(id), 1);
      setIdRevendasPermitidas(idRevendasPermitidas);
    }
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Administrador</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={cadastrarAdministrador}>
          <label htmlFor="nome">Nome</label>
          <input
            id="nome"
            name="nome"
            type="text"
            placeholder="Nome"
            onChange={(e) => setNome(e.target.value)}
            value={nome}
          ></input>
          <label htmlFor="cpf">CPF</label>
          <InputMask
            id="cpf"
            name="cpf"
            type="text"
            placeholder="CPF"
            mask="999.999.999-99"
            onChange={(e) => setCpf(e.target.value)}
            onBlur={validarBuscarCpf}
            value={cpf}
          />
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            type="text"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          ></input>
          <label htmlFor="senha">Senha</label>
          <input
            id="senha"
            name="senha"
            type="password"
            placeholder="Senha"
            onChange={(e) => setSenha(e.target.value)}
            value={senha}
          ></input>
          <label htmlFor="permissao">Permissão</label>
          <select
            id="permissao"
            name="permissao"
            onChange={(e) => setPermissao(e.target.value)}
            value={permissao}
          >
            <option disabled value="">Selecione a permissão</option>
            <option value="2">Smart Administrador</option>
            <option value="1">Smart Operador</option>
            <option value="0">Experiências SW</option>
            <option value="3">Vendedor</option>
            <option value="4">Auditor</option>
            <option value="5">Experiências SW Plus</option>
          </select>
          {permissao === '3' && (
            <div className="lojas">
              <label>Selecione as lojas</label>
              {revendasSelect.map((revenda) => (
                <div className="loja-checkbox">
                  <input
                    key={revenda.id}
                    type="checkbox"
                    id={revenda.id}
                    name={revenda.id}
                    value={revenda.id}
                    onClick={setRevendasPermitidas}
                  />
                  <label htmlFor={revenda.id}>{revenda.nomeFantasia}</label>
                </div>
              ))}
            </div>
          )}
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
